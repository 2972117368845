<template>
  <div class="weui-cells weui-cells_form" id="uploader">
    <div class="weui-cell">
      <div class="weui-cell__bd">
        <div class="weui-uploader">
          <div class="weui-uploader__hd">
            <p class="weui-uploader__title" style="text-align: left;">图片上传</p>
            <div style="display: none;" class="weui-uploader__info"><span id="uploadCount">0</span>/5</div>
          </div>
          <div class="weui-uploader__bd">
            <ul class="weui-uploader__files" id="uploaderFiles"></ul>
            <div class="weui-uploader__input-box">
              <input id="uploaderInput" class="weui-uploader__input" type="file" accept="image/*" multiple="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import weui from 'weui.js'
import { BASE_URL } from '../appConf.js'

export default {
  data () {
    return {
      imgsArr: [],
      imgObjsArr: []
    }
  },
  methods: {
    initUploader () {
      let that = this
      let uploadCount = 0
      // let imgsArr = []
      weui.uploader('#uploader', {
        url: `${BASE_URL}/wechatH5/work/upload/image`,
        auto: true,
        type: 'file',
        fileVal: 'image',
        compress: {
          width: 1200,
          height: 1200,
          quality: 0.8
        },
        onBeforeQueued: function (files) {
          // `this` 是轮询到的文件, `files` 是所有文件

          if (['image/jpg', 'image/jpeg', 'image/png', 'image/gif'].indexOf(this.type) < 0) {
            weui.alert('请上传图片')
            return false // 阻止文件添加
          }
          if (this.size > 10 * 1024 * 1024) {
            weui.alert('请上传不超过10M的图片')
            return false
          }
          if (files.length > 5) { // 防止一下子选择过多文件
            weui.alert('最多只能上传5张图片，请重新选择')
            return false
          }
          if (uploadCount + 1 > 5) {
            weui.alert('最多只能上传5张图片')
            return false
          }

          ++uploadCount

          // return true; // 阻止默认行为，不插入预览图的框架
        },
        onQueued: function () {
          console.log(this)
          that.imgObjsArr.push(this)
          that.imgsArr.push(this.url)

          // console.log(this.status); // 文件的状态：'ready', 'progress', 'success', 'fail'
          // console.log(this.base64); // 如果是base64上传，file.base64可以获得文件的base64

          // this.upload(); // 如果是手动上传，这里可以通过调用upload来实现；也可以用它来实现重传。
          // this.stop(); // 中断上传

          // return true; // 阻止默认行为，不显示预览图的图像
        },
        onBeforeSend: function (data, headers) {
          console.log(this, data, headers)
          // $.extend(data, { test: 1 }); // 可以扩展此对象来控制上传参数
          // $.extend(headers, { Origin: 'http://127.0.0.1' }); // 可以扩展此对象来控制上传头部

          // return false; // 阻止文件上传
          headers['x-openid'] = that.$store.state.openId
        },
        onProgress: function (procent) {
          console.log(this, procent)
          // return true; // 阻止默认行为，不使用默认的进度显示
        },
        onSuccess: function (ret) {
          console.log(this, ret)
          this.ret = ret
          // return true; // 阻止默认行为，不使用默认的成功态
        },
        onError: function (err) {
          console.log(this, err)
          that.$toast(err)
          // return true; // 阻止默认行为，不使用默认的失败态
        }
      })

      // 缩略图预览
      var uploadList = []
      var uploadCountDom = document.getElementById('uploadCount')
      document.querySelector('#uploaderFiles').addEventListener('click', function (e) {
        var target = e.target

        while (!target.classList.contains('weui-uploader__file') && target) {
          target = target.parentNode
        }
        if (!target) return

        var url = target.getAttribute('style') || ''
        var id = target.getAttribute('data-id')

        if (url) {
          url = url.match(/url\((.*?)\)/)[1].replace(/"/g, '')
        }
        var gallery = weui.gallery(url, {
          className: 'my-custom-name',
          onDelete: function () {
            weui.confirm('确定删除该图片？', function () {
              --uploadCount
              uploadCountDom.innerHTML = uploadCount
              var imgIndex = 0
              document.querySelectorAll('.weui-uploader__file').forEach(function (item, idx) {
                item.onclick = function () {
                  console.log(idx)
                  imgIndex = idx
                }
              })
              that.imgObjsArr.splice(imgIndex, 1)
              that.imgsArr.splice(imgIndex, 1)
              for (var i = 0, len = uploadList.length; i < len; ++i) {
                var file = uploadList[i]
                if (file.id === id) {
                  file.stop()
                  break
                }
              }
              target.remove()
              gallery.hide()
            })
          }
        })
      })
    }
  }
}
</script>
